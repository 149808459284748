export class NotificationItem {
  icon: string;
  message: string;
  jobId: string;
  time: number;
  orderId: number;
  quickActionId: number;
  resolutionTypeId: number;
  statusId: number;
  typeId: number;
  expiryTime: number;
  isRead: boolean;
  order: {
    amount: number;
    area: string;
    building: string;
    companyTrackId: string;
    customerName: string;
    customerPhone: string;
    floor: string;
    id: number;
    numberOfLargeBoxes: number;
    numberOfMidBoxes: number;
    numberOfSmallBoxes: number;
    pickupLocation: string;
    unit: string;
  };

  constructor(data: Partial<NotificationItem> = {}) {
    const actions: { [key: number]: string } = {
      1: 'CUSTOMER SAYS THEY ALREADY PAID.',
      2: 'CUSTOMER SAID THE AMOUNT TO BE COLLECTED IS WRONG.',
      3: 'CUSTOMER SAYS THEY CANNOT PAY.',
      4: 'CUSTOMER WANTS TO PAY BY CARD.',
      5: 'BOUNTY REPORTED WRONG NUMBER OF BOXES.',
    };

    this.typeId = data.typeId || 0;
    this.icon = data.icon || '';
    this.message = data.message || actions[this.typeId] || '';
    this.jobId = data.jobId || '';
    this.time = data.time || Date.now();
    this.orderId = data.order.id || 0;
    this.quickActionId = data.quickActionId || 0;
    this.resolutionTypeId = data.resolutionTypeId || 0;
    this.statusId = data.statusId || 0;
    this.expiryTime = data.expiryTime || Date.now();
    this.isRead = data.isRead || false;
    this.order = data.order || {
      amount: 0,
      area: '',
      building: '',
      companyTrackId: '',
      customerName: '',
      customerPhone: '',
      floor: '',
      id: 0,
      numberOfLargeBoxes: 0,
      numberOfMidBoxes: 0,
      numberOfSmallBoxes: 0,
      pickupLocation: '',
      unit: '',
    };
  }
}
