import { Injectable } from '@angular/core';
import { NotificationItem } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationCenterService {
  notifications: NotificationItem[] = [];
  constructor() {}

  /**
   * Stores a notification in the local storage if it is not a duplicate.
   *
   * @param data - The notification data to be stored.
   * @returns void
   */
  storeNotification(data: any) {
    const storedNotifications = localStorage.getItem('notifications');
    this.notifications = storedNotifications ? JSON.parse(storedNotifications) : [];
    const newNotification = new NotificationItem(data);

    const isDuplicate = this.notifications.some(
      (notification) =>
        notification.orderId === newNotification.orderId &&
        notification.quickActionId === newNotification.quickActionId &&
        notification.expiryTime === newNotification.expiryTime
    );
    if (!isDuplicate) {
      this.notifications.push(newNotification);
      localStorage.setItem('notifications', JSON.stringify(this.notifications));
    } else {
      console.warn('Duplicate notification. Skipping storage.');
    }
  }

  /**
   * Retrieves the list of notifications from local storage.
   *
   * @returns {Promise<NotificationItem[]>} A promise that resolves with an array of NotificationItem objects if found in local storage,
   * or rejects with an empty array if no notifications are found.
   */
  getNotifications(): Promise<NotificationItem[]> {
    return new Promise<NotificationItem[]>((resolve, reject) => {
      const notifications = localStorage.getItem('notifications');
      if (notifications) {
        this.notifications = JSON.parse(notifications);
        resolve(this.notifications);
      } else {
        reject([]);
      }
    });
  }

  /**
   * Marks a given notification as read.
   *
   * This method retrieves the list of notifications from local storage,
   * finds the notification that matches the provided notification item,
   * and marks it as read. The updated list of notifications is then
   * saved back to local storage.
   *
   * @param {NotificationItem} notification - The notification item to mark as read.
   */
  markAsRead(notification: NotificationItem) {
    const storedNotifications = localStorage.getItem('notifications');
    this.notifications = storedNotifications ? JSON.parse(storedNotifications) : [];
    const index = this.notifications.findIndex((n) => JSON.stringify(n) === JSON.stringify(notification));
    if (index > -1) {
      this.notifications[index].isRead = true;
      localStorage.setItem('notifications', JSON.stringify(this.notifications));
    }
  }
}
