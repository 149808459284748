import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@app/@core';
import { GlobalEventsService } from '@app/global-events.service';
import { defer, Subscription } from 'rxjs';
import { delay, repeatWhen } from 'rxjs/operators';
import { QuickActionsService } from '../quick-actions.service';
import { NotificationCenterService } from '@app/notification-center/service/notification-center.service';
import { NotificationItem } from '@app/notification-center/models/notification.model';

const log = new Logger('ActionsList');

@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss'],
})
export class ActionsListComponent implements OnInit, OnDestroy {
  actions: any[] = [];
  listener: Subscription;

  constructor(
    private quickActionsService: QuickActionsService,
    private router: Router,
    private globalEventsService: GlobalEventsService,
    private notifi: NotificationCenterService
  ) {}

  ngOnInit() {
    this.startListening();
    this.globalEventsService.onQuickActionResolved.subscribe((action: any) => {
      if (action) {
        this.actions.splice(
          this.actions.findIndex((a: any) => {
            return a.id === action.id;
          }),
          1
        );
      }
    });
  }

  startListening() {
    this.listener = defer(() => this.quickActionsService.getActions('1'))
      .pipe(repeatWhen((n) => n.pipe(delay(5 * 1000))))
      .subscribe((res: any[]) => {
        const activeActions = res.filter((action) => action.statusId === 1);
        // if the list is empty, emit all quick actions resolved so that it get resolved.
        if (this.actions.length > 0 && activeActions.length === 0) {
          this.globalEventsService.onQuickActionResolved.emit();
        }
        if (activeActions.length > 0) {
          this.playAudio();
        }
        // This means that a new action has been added
        if (this.actions.length !== activeActions.length) {
          this.notifi.storeNotification(activeActions[0]);
        }
        this.actions = activeActions;
      });
  }

  actionTitle(actionTypeId: number): string {
    const actions = {
      1: 'CUSTOMER SAYS THEY ALREADY PAID.',
      2: 'CUSTOMER SAID THE AMOUNT TO BE COLLECTED IS WRONG.',
      3: 'CUSTOMER SAYS THEY CANNOT PAY.',
      4: 'CUSTOMER WANTS TO PAY BY CARD.',
      5: 'BOUNTY REPORTED WRONG NUMBER OF BOXES.',
    };
    if (actions[actionTypeId]) {
      return actions[actionTypeId];
    }
    return '';
  }

  goToActionDetails(action: any) {
    // this seems a weird solution,
    // but it solves the router not detecting changes
    // with only route params,
    // this can solved with cleaner ways later
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(`/order/${action.order.id}`);
    });
  }

  playAudio() {
    let audio = new Audio();
    audio.src = 'assets/images/actions/notify-sound.mp3';
    audio.volume = 1;
    audio.load();
    audio.play();
  }

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener.unsubscribe();
    }
  }
}
